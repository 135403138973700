import { css } from '@emotion/react';
import styled from '@emotion/styled';
import React, { useCallback } from 'react';
import { BLACK } from '../../constants/colors';
import { HistoryDataItem } from '../../data/history';
import { UrbaneFont } from '../../styles/common';
import { getDesktopStyle, showOnDesktopStyle, showOnMobileStyle } from '../../styles/layout';
import { pxToRem } from '../../utils/common';

interface HistoryListItemProps {
	data: HistoryDataItem;
	isOpen: boolean;
	onClick: (id: number) => void;
}

const HistoryListItem = ({ data, isOpen, onClick }: HistoryListItemProps) => {
	const { id, color, record, year } = data;
	const handleClickItem = useCallback(() => {
		onClick(id);
	}, [id, onClick]);

	return (
		<ListItem isOpen={isOpen} onClick={handleClickItem}>
			<YearButtonWrapper isOpen={isOpen} color={color}>
				<YearButton type="button" isOpen={isOpen}>
					{year}
				</YearButton>
			</YearButtonWrapper>
			{isOpen && (
				<RecordList>
					{record.map((record) => (
						<RecordItem key={record.id}>
							<RecordDate>{record.date}</RecordDate>
							<RecordText>
								{record.text.map((text) => (
									<span style={{fontWeight:400}} key={text}>{text}</span>
								))}
							</RecordText>
						</RecordItem>
					))}
				</RecordList>
			)}
			<IconButton type="button" isOpen={isOpen} color={color} aria-label="내용 더보기">
				{isOpen ? (
					<>
						<svg
							width="22"
							height="22"
							viewBox="0 0 22 22"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							css={showOnDesktopStyle}
						>
							<rect y="9" width="22" height="4" fill={color} />
						</svg>
						<svg
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							css={showOnMobileStyle}
						>
							<rect y="7" width="16" height="2" fill={color} />
						</svg>
					</>
				) : (
					<>
						<svg
							width="22"
							height="22"
							viewBox="0 0 22 22"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							css={showOnDesktopStyle}
						>
							<path fillRule="evenodd" clipRule="evenodd" d="M13 9V0H9V9H0V13H9V22H13V13H22V9H13Z" fill={BLACK} />
						</svg>
						<svg
							width="16"
							height="16"
							viewBox="0 0 16 16"
							fill="none"
							xmlns="http://www.w3.org/2000/svg"
							css={showOnMobileStyle}
						>
							<rect y="7" width="16" height="2" fill={BLACK} />
							<rect x="9" width="16" height="2" transform="rotate(90 9 0)" fill={BLACK} />
						</svg>
					</>
				)}
			</IconButton>
		</ListItem>
	);
};

export default HistoryListItem;

const RecordText = styled.p`
	display: flex;
	text-align: left;
	font-size: ${pxToRem(12)};

	${getDesktopStyle(css`
		width: 74%;
		justify-content: space-between;
		font-size: ${pxToRem(20)};
	`)}
`;

const RecordDate = styled.p`
	flex: 0 0 ${pxToRem(31)};
	margin-right: ${pxToRem(6)};
	font-size: ${pxToRem(10)};
	text-align: left;
	display: inline-block;

	${getDesktopStyle(css`
		flex: 0 0 ${pxToRem(38)};
		margin-right: ${pxToRem(14)};
		font-size: ${pxToRem(20)};
		font-weight: 700;
		text-align: right;
		min-width: ${pxToRem(52)};
	`)}
`;

const RecordItem = styled.li`
	display: flex;
	align-items: baseline;
	line-height: ${pxToRem(22)};
	${getDesktopStyle(css`
		line-height: ${pxToRem(35)};
	`)}
`;

const RecordList = styled.ul(
	() => css`
		flex: auto;
		/*margin-top: -3px;*/
		width: 100%;
		padding: ${pxToRem(12)} 0 0 ${pxToRem(24)};
	
		${getDesktopStyle(css`
			margin-top: 0;
			padding: 0; width:auto;
		`)}
	`,
);

const IconButton = styled.button(
	({ isOpen, color }: { isOpen: boolean; color: string }) => css`
		flex: 0 0 ${pxToRem(16)};
		color: ${isOpen ? color : BLACK};
		/* width: ${pxToRem(16)};
		height: ${pxToRem(16)}; */
		display: flex;
		justify-content: center;
		align-items: center;
		position:absolute; right:0; 
		// top:${pxToRem(18)};
		margin-right: ${pxToRem(10)};
		
		${getDesktopStyle(css`
			flex: 0 0 ${pxToRem(24)};
			/* width: ${pxToRem(24)};
			height: ${pxToRem(24)}; */
			margin-right: ${pxToRem(20)};
		`)}
	`,
);

const YearButton = styled.button(
	({ isOpen }: { isOpen: boolean }) => css`
		font-weight: 400;
		text-align: left;		
		${getDesktopStyle(css`
			font-weight: ${isOpen ? 600 : 400};
		`)}
	`,
);

const YearButtonWrapper = styled.div(
	({ isOpen, color }: { isOpen: boolean; color: string }) => css`
		${UrbaneFont};
		/*flex: ${isOpen ? `0 0 ${pxToRem(50)}` : `0 0 ${pxToRem(74)}`};*/
		font-size: ${pxToRem(13)};
		line-height: ${pxToRem(16)};
		text-align: left;
		color: ${isOpen ? color : BLACK};	
		width: ${isOpen ? "100%" : "auto"};
		margin-left: ${pxToRem(10)};

		${getDesktopStyle(css`
			flex: 0 0 ${pxToRem(324)};
			font-size: ${pxToRem(30)};
			line-height: ${pxToRem(36)};
			margin-left: ${pxToRem(20)};
		`)}
	`,
);

const ListItem = styled.li(
	({ isOpen }: { isOpen: boolean }) => css`
		/*height: ${isOpen ? 'auto' : pxToRem(40)};*/
		padding: ${pxToRem(18)} 0;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: ${isOpen ? 'flex-start' : 'center'};
		border-bottom: 0.75px solid ${BLACK};
		position: relative;

		${getDesktopStyle(css`
			padding: ${pxToRem(20)} 0;
			border-bottom: 1px solid ${BLACK};
			height: ${isOpen ? 'auto' : pxToRem(76)};
			cursor: pointer;
		`)}
	`,
);
